<script>

import VueSimpleMarkdownParserComponent from '../../../node_modules/vue-simple-markdown/src/vue-simple-markdown.component';
import  { VueSimpleMarkdownParser } from '../../../node_modules/vue-simple-markdown/src/vue-simple-markdown-parser';

export default {
  extends: VueSimpleMarkdownParserComponent,

  name: 'markdown-parser',
  computed: {
    parsed () {
      if (!this.source) {
        return '';
      }

      let source = this.prerender(this.source.toString())

      this.$router.getRoutes().forEach((route) => {
        source = source.replaceAll('(' + route.name +  ')', '(' + route.path + ')');
      });

      source = VueSimpleMarkdownParser.parse(source, {
        emoji: this.emoji,
        heading: this.heading,
        highlight: this.highlight,
        horizontalLine: this.horizontalLine,
        image: this.image,
        inlineCode: this.inlineCode,
        italic: this.italic,
        link: this.link,
        linkify: this.linkify,
        lists: this.lists,
        strong: this.strong,
        blockquote: this.blockquote,
        table: this.table
      })

      return this.postrender(source)
    }
  }
}

</script>

